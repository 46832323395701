<template>
  <div class="month-invoices-wrapper">
    <div v-on:dragover="onDragOver($event)" v-on:drop="onDrop($event)" class="items" v-if="!pinnedHtml">
      <div draggable="true" v-on:dragstart="onDragStart($event, invoice)" :class="['invoice_status', 'invoice_type_' + (invoice.parent_id ? 'sub' : 'simple'), 'invoice_status_' + invoice.status, invoice.upsell ? 'invoice_upsell' : '']" v-for="(invoice, index) in invoices" :key="index" @click="showEditPopup(invoice)">
        <span>{{ priceFormatter(invoice.amount) }}</span><span class="invoice_applied" v-if="invoice.applied_date"></span>
      </div>
    </div>
    <div v-else v-html="pinnedHtml"></div>
  </div>
</template>
<script>
import InvoicePopup from '@/agGridV2/components/invoice-popup.component.vue'
// API
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'

export default {
  data() {
    return {
      pinnedHtml: '',
      invoices: []
    }
  },
  mounted() {
    if (this.params.node.rowPinned) {
      if (this.params?.value?.total || this.params?.value?.total === 0) {
        const unpaidHtml = this.params?.value?.unpaid ? `<span style="color: #FF3D34">(${SimpleHelper.priceFormatter(this.params.value.unpaid, true)})</span>` : ''
        this.pinnedHtml = `<div>${SimpleHelper.priceFormatter(this.params.value.total, true)} ${unpaidHtml}</div>`
      }
    } else {
      if (this.params.value && this.params.value.length > 0) {
        this.invoices = this.params.value
      }
    }
  },
  methods: {
    isExpenses() {
      const path = window.location.href
      return path.includes('expenses/')
    },
    priceFormatter(price) {
      return SimpleHelper.priceFormatter(price)
    },
    showEditPopup(invoice) {
      this.$modal.show(
        InvoicePopup,
        {
        invoice
      }, {
        width: '700px'
      })
    },
    onDragStart(event, invoice) {
      event.dataTransfer.setData(
        'text/plain',
        JSON.stringify({
          rowId: this.params.node.id,
          columnName: this.params.colDef.field,
          invoice
        })
      )
    },
    onDragOver(event) {
      const types = event.dataTransfer.types
      const dragSupported = types.length
      if (dragSupported) {
        event.dataTransfer.dropEffect = 'move'
      }
      event.preventDefault()
    },
    async onDrop(event) {
      // Get Data
      const data = event.dataTransfer.getData('text/plain')
      const parsedData = JSON.parse(data)
      if (parsedData.rowId !== this.params.node.id) {
        alert('Cannot be dragged to another line!')
        return false
      }

      // Update old Row
      const oldRow = this.params.api.getRowNode(parsedData.rowId)
      const oldData = oldRow.data
      if (oldData[parsedData.columnName]) {
        oldData[parsedData.columnName] = oldData[parsedData.columnName].filter((i) => i._id !== parsedData.invoice._id)
        oldRow.updateData(oldData)
      }
      // Update Applied Date
      const newColumn = this.params.colDef.field
      const newData = this.params.data
      const invoice = parsedData.invoice
      const invoiceCreatedAt = new Date(invoice.created_date)
      const splittedColumnName = newColumn.split('_')
      const columnMonth = splittedColumnName[0]
      const columnYear = splittedColumnName[1]

      if (
        invoiceCreatedAt &&
        parseInt(columnYear, 10) === parseInt(invoiceCreatedAt.getFullYear(), 10) &&
        parseInt(columnMonth, 10) === parseInt(invoiceCreatedAt.getMonth(), 10)
      ) {
        delete invoice.applied_date
      } else {
        const newAppliedDate = new Date()
        newAppliedDate.setFullYear(columnYear, columnMonth, 1)
        invoice.applied_date = newAppliedDate
      }

      // Update Column
      const invoices = newData[newColumn] || []
      invoices.push(invoice)
      const updatedInvoices = [...invoices]
      if (invoice) {
        this.params.api.setGridOption('context', {
          event: 'update_invoice',
          invoice
        })
      }
      this.params.node.setDataValue(newColumn, updatedInvoices)
    }
  }
}
</script>

<style lang="scss" scoped>
.month-invoices-wrapper {
  width: 100%;
  height: 100%;
  .items {
    width: 100%;
    height: 100%;
  }
  .invoice_applied {
    margin-left: 8px;
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: sub;
    background-size: contain;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0iIzFjNzhkZiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyLjk1NDUgMi42MTM3NUgxNy44NDA5VjEuMTM2NDdIMTYuMjVWMi42MTM3NUg4Ljg2MzY0VjEuMTM2NDdINy4yNzI3M1YyLjYxMzc1SDIuMDQ1NDVDMS41OTA5MSAyLjYxMzc1IDEuMjUgMi45NTQ2NiAxLjI1IDMuNDA5MlYyMy4wNjgzQzEuMjUgMjMuNTIyOCAxLjU5MDkxIDIzLjg2MzcgMi4wNDU0NSAyMy44NjM3SDIzLjA2ODJDMjMuNTIyNyAyMy44NjM3IDIzLjg2MzYgMjMuNTIyOCAyMy44NjM2IDIzLjA2ODNWMy40MDkyQzIzLjc1IDIuOTU0NjYgMjMuNDA5MSAyLjYxMzc1IDIyLjk1NDUgMi42MTM3NVpNNy4yNzI3MyA0LjIwNDY2VjUuNjgxOTNIOC44NjM2NFY0LjIwNDY2SDE2LjM2MzZWNS42ODE5M0gxNy45NTQ1VjQuMjA0NjZIMjIuMjcyN1Y5LjIwNDY2SDIuODQwOTFWNC4yMDQ2Nkg3LjI3MjczWk0yLjg0MDkxIDIyLjI3MjhWMTAuNzk1NkgyMi4yNzI3VjIyLjI3MjhIMi44NDA5MVoiIGZpbGw9IiM1RjVGNUYiLz4KPHBhdGggZD0iTTE2LjcgMTRMMTQuNzAwMSAxMkwxMy4zMTQyIDEzLjM4NThMMTUuMzE0MiAxNS4zODU4TDE2LjcgMTRaIiBmaWxsPSIjNUY1RjVGIi8+CjxwYXRoIGQ9Ik04LjUgMjAuMTk5OUwxMC43IDIwTDE0LjQ4NTggMTYuMjE0MUwxMi40ODU4IDE0LjIxNDFMOC43IDE3Ljk5OTlMOC41IDIwLjE5OTlaIiBmaWxsPSIjNUY1RjVGIi8+Cjwvc3ZnPgo=)
  }
  .invoice_type_sub {
    border: 1px dashed;
  }
  .invoice_upsell {
    background-color: #FFE58B;
  }
}
</style>
