<template>
  <div class="invoice-edit-wrapper">
    <div class="invoice-edit-wrapper__close" @click="closePopup"></div>
    <div class="invoice-info" v-if="invoice._id">
      <div class="heading">Invoice Information:</div>
      <div class="info">
        <p>Number: {{ invoice.quickbook_id }}</p>
        <p :class="'invoice-status-' + invoice.status">Status: {{ invoice.status }}</p>
        <p>Amount: {{ priceFormatter(invoice.amount) }}</p>
        <p v-if="invoice.parent_id">Parent Amount: {{ priceFormatter(invoice.parent_amount) }}</p>
        <p>Creation date: {{ dateFormatter(invoice.created_date) }}</p>
        <p>Payment date: {{ dateFormatter(invoice.payment_date) }}</p>
        <p>Applied date: {{ dateFormatter(invoice.applied_date) }}</p>
        <p>Note: <br>{{ invoice.note }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import 'vue2-datepicker/index.css'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'

export default {
  data() {
    return {
      invoice: {
        id: null,
        status: null,
        amount: null,
        created_at: null,
        paid_date: null,
        applied_date: null,
        note: null
      }
    }
  },
  mounted() {
    this.invoice = this.$attrs.invoice
  },
  methods: {
    priceFormatter(price) {
      return SimpleHelper.priceFormatter(price)
    },
    dateFormatter(date) {
      return SimpleHelper.dateFormatter(date)
    },
    closePopup(e) {
      const target = e.target
      const wrapEl = target.closest('.vm--container')
      if(wrapEl) {
        wrapEl.querySelector('.vm--overlay').click()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.invoice-edit-wrapper {
  padding: 20px;
  display: flex;
  justify-content: space-between;

  &__close {
    width: 32px;
    height: 32px;
    margin: 0;
    padding: 0;
    border-bottom: none;

    position: absolute;
    top: 8px;
    right: 15px;
    z-index: 1;

    cursor: pointer;
    transition: 300ms ease;

    &::before,
    &::after {
        content: '';

        width: 70%;
        height: 2px;

        position: absolute;
        top: 50%;
        left: 50%;

        background-color: #7987FF;
    }

    &::before {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
    &::after {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &:hover {
        opacity: .7;
    }
  }
  .heading {
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
  }
  .fields {
    .field {
      display: flex;
      flex-direction: column;
    }
  }
  .invoice-status-Paid {
    color: green;
  }
  .invoice-status-Unpaid {
    color: red;
  }
}
</style>
