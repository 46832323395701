<template>
  <div class="request-year">
    <v-select
      class="years-selector"
      v-model="requestYear"
      :options="years"
      :clearable="false"
    >
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  name: 'header-request-year',
  components: { vSelect },
  data() {
    return {
      years: ['2021', '2022', '2023', '2024', 'All']
    }
  },
  computed: {
    requestYear: {
      get() {
        return this.$store.getters.getRequestYear(this.$route.name)
      },
      set(value) {
        this.$store.dispatch('setRequestYear', { page: this.$route.name, query: value })
      }
    }
  },
  mounted() {
    this.$emitter.on('header_reset_filters', () => {
      this.requestYear = new Date().getFullYear()
    })
  },
  beforeDestroy() {
    this.$emitter.off('header_reset_filters')
  }
}
</script>
